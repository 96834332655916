<template>
  <div>
    <!-- Calendar Icon to Open the Popup -->
    <font-awesome-icon 
      :icon="['fas', 'calendar-day']" 
      class="calendar-icon"
      @click="toggleCalendar"
    />

    <!-- Popup Calendar -->
    <div v-if="showCalendar" class="calendar-popup-overlay" @click.self="toggleCalendar">
      <div class="calendar-popup">
        <!-- Calendar Header -->
        <div class="popup-header-calendar">
          <h2>{{ $t('new_asset.calendar_title') }}</h2>
          <h2>{{ languageLabel }}</h2>
        </div>
        
        <div class="calendar-header">
          <button @click="goToPreviousYear" title="Go to previous year" class="nav-button">&laquo;</button>
          <button @click="goToPreviousMonth" title="Go to previous month" class="nav-button">&lsaquo;</button>
          <div class="month-year">
            {{ monthNames[currentMonth] }} {{ currentYear }}
          </div>
          <button @click="goToNextMonth" title="Go to next month" class="nav-button">&rsaquo;</button>
          <button @click="goToNextYear" title="Go to next year" class="nav-button">&raquo;</button>
        </div>

        <!-- Calendar Table -->
        <table class="calendar-table">
          <thead>
            <tr>
              <th v-for="day in daysOfWeek" :key="day">{{ day }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(week, index) in weeks" :key="index">
              <td
                v-for="day in week"
                :key="day.date"
                :class="{
                  'highlighted-date': day.isHighlighted,
                  'empty': !day.date
                }"
              >
                {{ day.date || '' }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Footer -->
        <div class="calendar-footer">
          <p class="footer-text">
            {{ $t('new_asset.calendar_footer') }}
          </p>
        </div>

        <!-- Close Button -->
        <button class="close-button" @click="toggleCalendar">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCalendar: false, // Controls the visibility of the calendar popup
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      monthNames: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December",
      ],
      weeks: [], // Holds the days of the current month
      customHighlightDates: {
        en: {
          "2024-12": [6, 20],
          "2025-01": [3, 17,31],
          "2025-02": [14, 28],
          "2025-03": [7, 21],
          "2025-04": [4, 18],
          "2025-05": [2,16,30],
          "2025-06": [13, 27],
        },
        nl: {

          "2024-12": [13, 27],
          "2025-01": [10, 24],
          "2025-02": [7, 21],
          "2025-03": [14, 28],
          "2025-04": [11, 25],
          "2025-05": [9, 23],
          "2025-06": [6, 20],
        },
      },
    };
  },
  created() {
    let defapp_lang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE');
    this.currentlang = defapp_lang; // Default to English if no language is set
    this.generateCalendar();
  },
  emits: ['exitCalendar'],
  computed: {
    languageLabel() {
      if (this.currentlang === 'en') {
        return "Language: English (US)";
      } else if (this.currentlang === 'nl') {
        return "Taal: Dutch (Belgian)";
      }
      return "Language not supported"; // Fallback for unsupported languages
    },
  },
  methods: {
    toggleCalendar() {
      this.showCalendar = !this.showCalendar;
      this.$emit('exitCalendar');
    },
    generateCalendar() {
      const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1);
      const lastDayOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);

      // Start the calendar from the first Sunday before the first day of the month
      const startDate = new Date(firstDayOfMonth);
      startDate.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay());

      // End the calendar on the Saturday after the last day of the month
      const endDate = new Date(lastDayOfMonth);
      endDate.setDate(lastDayOfMonth.getDate() + (6 - lastDayOfMonth.getDay()));

      const days = [];
      let currentDate = new Date(startDate);

      // Determine the custom highlight dates for the current language and month
      const currentKey = `${this.currentYear}-${String(this.currentMonth + 1).padStart(2, "0")}`;
      const highlightedDates = (this.customHighlightDates[this.currentlang] && this.customHighlightDates[this.currentlang][currentKey]) || [];

      // Generate all days between startDate and endDate
      while (currentDate <= endDate) {
        const isHighlighted =
          highlightedDates.includes(currentDate.getDate()) &&
          currentDate.getMonth() === this.currentMonth;
        days.push({
          date: currentDate.getMonth() === this.currentMonth ? currentDate.getDate() : null,
          isHighlighted,
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Split days into weeks
      this.weeks = [];
      for (let i = 0; i < days.length; i += 7) {
        this.weeks.push(days.slice(i, i + 7));
      }
    },
    goToPreviousMonth() {
      this.currentMonth--;
      if (this.currentMonth < 0) {
        this.currentMonth = 11;
        this.currentYear--;
      }
      this.generateCalendar();
    },
    goToNextMonth() {
      this.currentMonth++;
      if (this.currentMonth > 11) {
        this.currentMonth = 0;
        this.currentYear++;
      }
      this.generateCalendar();
    },
    goToPreviousYear() {
      this.currentYear--;
      this.generateCalendar();
    },
    goToNextYear() {
      this.currentYear++;
      this.generateCalendar();
    },
  },
};
</script>

  
<style>

.popup-header-calendar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #000000;
    padding-bottom: 10px;
    margin-bottom: 5px;
  }
  
  .popup-header-calendar h2 {
    font-size: 1.1rem;
    font-weight: 300;
    color: #333;
    margin: 0;
    line-height: 2rem;
  }
  
.calendar-icon {
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
  transition: color 0.3s ease;
}

.calendar-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay for focus */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.calendar-popup {
  background: #ffffff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  width: 100%;
  text-align: center;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.highlighted-date {
  background: #ffd700; /* Gold for highlighted dates */
  color: #333;
  font-weight: bold;
  border: 2px solid #ffa500; /* Orange border */
}

.nav-button {
  background: #7878e9;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.nav-button:hover {
  background: #5b5baf;
}

.nav-button[data-tooltip] {
  position: relative;
}

.nav-button[data-tooltip]::after {
  content: attr(data-tooltip);
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.nav-button[data-tooltip]:hover::after {
  opacity: 1;
}

.month-year {
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

.calendar-table {
  width: 100%;
  border-collapse: collapse;
}

.calendar-table th,
.calendar-table td {
  width: 14.2%;
  padding: 10px;
  text-align: center;
  color: #333;
  border: 1px solid #ddd;
}

.calendar-table th {
  background: #f4f4f4;
  font-weight: bold;
}

.calendar-table td.empty {
  background: #f9f9f9;
}

.calendar-table td.highlighted-saturday {
  background: #7878e9; /* Light blue highlight for alternate Saturdays */
  color: #000; /* Dark blue text */
  font-weight: bold;
  border: 2px solid #43436e;
}

.calendar-table td.highlight {
  background: #ffe4e1; /* Light pink for weekends */
  color: #d32f2f;
  font-weight: bold;
}

.close-button {
  margin-top: 0px;
  background: #d32f2f;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.close-button:hover {
  background: #b71c1c;
}

.calendar-footer {
  margin-top: 15px;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  text-align: center;
}

.footer-text {
  font-size: 14px;
  color: #555;
}

  
</style>