import { createApp } from 'vue';
import App from './App.vue';
import store from './store.js';
import router from './router';
import vClickOutside from "click-outside-vue3";
import { i18n } from './translations/index.js';
import {VueReCaptcha} from "vue-recaptcha-v3";
import {VueCal} from "vue-cal";

// fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(fas);

import AxiosHelper from '@/helpers/axios_helper.js';
const axiosHelper = new AxiosHelper();
axiosHelper.axiosSetup();

// our custom plugins
import D3export from './plugins/D3export.js';

// styles
import '@/css/swal.scss';

// 

// import '@/css/onboarding_steps_style.scss';
// import '@/css/asset_style.scss';
import '@/css/bootstrap.min.css';
import '@/css/index.css';
import '@/css/custom.css';
import Quasar from 'quasar/src/vue-plugin.js';

import quasarUserOptions from './quasar-user-options'

// we need jQuery for Select2
window.$ = window.jQuery = require('jquery');

// Google Tag Manager
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],                                                                                                              
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=                                                                                                                  
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);                                                                                                     
})(window,document,'script','dataLayer','GTM-PKRWHR3');

// Stripe (hopefully)
let recaptchaScript = document.createElement('script')
recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
document.head.appendChild(recaptchaScript)

var app = createApp(App)
    .use(Quasar, quasarUserOptions)
    .use(store)
    .use(router)
    .use(D3export)
    .use(vClickOutside)
    .use(i18n)
    .use(VueCal)
    .use(VueReCaptcha, {siteKey: '6LcXh9QaAAAAAOay0bgPlmuXcE8Xw8iKGxhJ3WgK',
                                loaderOptions:{autoHideBadge: true }})
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#mainDiv");
