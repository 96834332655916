<template>
    <LoadingAppWide v-if="loading" />
    <div class="popup-overlay" v-if="visible" @click.self="$emit('exit')">
      
      <div class="popup-container-add">
        <q-card-section class="row items-center q-pb-none">
            <q-space />
            <q-btn icon="close" flat round dense @click.self="$emit('exit')" />
        </q-card-section>

        <q-card-section class="upload-info">
            <div class="top-text">{{ $t('new_asset.add_new_item') }}</div>
        </q-card-section>

        <div class="wrapper">
            <div class="input-form">
                <input v-model="displayName" :placeholder="$t('new_asset.type_to_create_asset')" />
                <q-btn @click="textAssetAdd" :disabled="displayName.length==0" class="search-button">
                    <img src="@/assets/icons/plus-white.svg" />
                </q-btn>
            </div>
        </div>

        <q-list bordered class="rounded-borders upload-list">
            <candidate-asset v-for="file in files" :key="file.uuid" :file="file" :ref="setCandidateAssetRef" @remove="onFileRemove">
            </candidate-asset>
        </q-list>
<!-- 
        <div class="dialogs" v-if="files.size>0">
            <p v-if="insufficientMindcoins">
                {{ $t("new_asset.insufficient_credits") }}
            </p>
            <p class="font-weight-bold">
                {{ $t("new_asset.total_mindcoins", { total: mindcoinTotal }) }}
            </p>
        </div>
        <div class="buttons" v-if="files.size>0">
            <button class="btngrey" @click="close">
                {{ $t("message.cancel") }}
            </button>
            <button class="btn3" :disabled="!uploadAllowed" @click="createAssets">
                {{ $t("message.asset_creator_add") }}
            </button>
        </div> -->
        <div class="popup-footer">
            <button class="back-button" @click="$emit('goBackMain')">{{ $t('new_asset.back') }}</button>
            <button class="next-button" :disabled="files.length == 0" @click="goToChooseTrack">{{ $t('new_asset.next') }}</button>
        </div>


      </div>
    </div>
</template>

<script>
import {
    v4 as uuidv4
} from 'uuid';
import axios from "axios";
import { alert_error, toast_success, genericDialog,alert_reminding } from '@/helpers/alert_helper.js';
import CandidateAsset from "./CandidateAsset.vue";
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';


export default {
    components: {
        CandidateAsset, LoadingAppWide
    },

    emits: ['resetSearch', 'goBackMain', 'goToChooseTrack', 'exit', 'textUploadTriggered'],

    data: function () {
        return {
            displayName: '',
            files: [],
            candidateAssets: [],
            visible: false,
            loading: false,
        }
    },

    created() {
        this.word_price = this.$store.getters.get_price('private_word_asset')
        this.image_price = this.$store.getters.get_price('private_image_asset')
        this.av_price = this.$store.getters.get_price('private_av_asset')
    },

    computed: {
        acceptableAssets() {
            return this.candidateAssets.filter(ca => ca.component.info.acceptable);
        },
        uploadAllowed() {
            return !this.insufficientMindcoins &&
                    this.allQuestionsAnswered &&
                    this.acceptableAssets.length > 0;
        },
        insufficientMindcoins() {
            return this.mindcoinTotalValue > this.$store.state.mindcoins;
        },
    },
    methods: {
        open({ files = [], candidateAssets = [] } = {}) {
            // Clear arrays to prevent duplicates
            this.files = [];
            this.candidateAssets = [];

            // Re-add files
            if (files && Array.isArray(files)) {
                this.files = [...files];
            }

            // Re-add candidate assets
            if (candidateAssets && Array.isArray(candidateAssets)) {
                this.candidateAssets = [...candidateAssets];
            }

            this.recalculateMindcoinTotal();
            this.visible = true;
        },
        close: function () {
            this.candidateAssets = [];
            this.files = [];
            this.visible = false;
        },
        closeVisible() {
            this.visible = false;
        },
        recalculateMindcoinTotal() {
            const total = this.acceptableAssets
                .map(ca => ca.component.info.price)
                .reduce((a, b) => a + b, 0);
            this.mindcoinTotalValue = isNaN(total) ? 0 : total;
        },
        goToChooseTrack() {
            this.recalculateMindcoinTotal();
            this.$emit('goToChooseTrack', {
                acceptableAssets: this.acceptableAssets.map(a => a.component),
                mindcoinsTotal: this.mindcoinTotalValue,
                files: [...this.files],
                candidateAssets: [...this.candidateAssets]
            });
        },
        textAssetAdd: async function () {
            for (const ca of this.candidateAssets) {
                if (ca.info.component.displayName === this.displayName) {
                    alert_error(this.$t('message.duplicate_asset'));
                    return;
                }
            }
            // we don't allow dictionary words to be used as assets
            const payload = {
                'word': this.displayName
            };
            try {
                const response_exists = await axios.post('/api/cas/words/exists', payload);
                if (response_exists.data.exists) {
                    alert_reminding(this.$t('new_asset.word_exists_in_network'));
                    return;
                }
                const response_duplicate = await axios.post('/api/cas/assets/asset_exists_for_word', payload);
                if (response_duplicate.data.asset_exists_for_word) {
                    alert_error(this.$t('new_asset.duplicate_order_attempt'));
                    return;
                }
            } catch (error) {
                alert_error(this.$t('message.general_server_error'));
                return;
            }

            const mockFile = new File([""], `${this.displayName}.txt`, {
                type: 'text/plain'
            });
            mockFile.uuid = uuidv4();
            this.files.push(mockFile);
            this.displayName = '';
        },
        onFileRemove(file) {
            // Remove file from files
            this.files = this.files.filter(f => f !== file);
            // Clear candidateAssets since they may reference removed files
            this.candidateAssets = [];
            this.recalculateMindcoinTotal();
        },
        setCandidateAssetRef: function (ca) {
            if (!ca) return;
            // Check for duplicates
            if (!this.candidateAssets.some(existing => existing.id === ca.file.uuid)) {
                this.candidateAssets = [...this.candidateAssets, { id: ca.file.uuid, component: ca }];
                this.recalculateMindcoinTotal();
            }
        },
        async upload({totalMindcoins, isConfidential, files, candidateAssets, acceptableAssets, chosenETA }) {
            // first, we ask the user to confirm the upload
            this.isConfidential = isConfidential;
            this.acceptedAssets = acceptableAssets;
            this.chosenETA = chosenETA;
            this.totalMindcoins = totalMindcoins / (this.acceptedAssets.length || 1);
            this.files = files;
            this.candidateAssets = candidateAssets;
            const dialog_options = {
                title: this.$t('new_asset.create_selected_asset'),
                text: this.$t('new_asset.this_will_cost', {
                    price: this.totalMindcoins * (this.acceptedAssets.length || 1)
                }) + '\n' + this.$t('new_asset.short_notification_text'),
                confirmButtonText: this.$t("message.asset_creator_add"),
                cancelButtonText: this.$t("message.cancel"),
            };
            const dialog_result = await genericDialog(dialog_options);
            if (!dialog_result.isConfirmed) {
                return;
            }

            // next, check if the user has enough credits
            await this.$store.dispatch('get_mindcoin_balance');
            if (this.$store.state.mindcoins < this.totalMindcoins * (this.acceptedAssets.length || 1)) {
                alert_error(this.$t('new_asset.insufficient_credits'));
                return;
            }

            // if the user has enough credits, we upload the files one by one
            for (const ca of this.acceptedAssets) {
                this.$emit('exit');
                const payload = {
                    'word': ca.info.displayName,
                    'confidential': this.isConfidential,
                    'mindcoins': this.totalMindcoins  * (this.acceptedAssets.length || 1),
                    'expedite': this.chosenETA,
                };
                this.loading = true;
                try {
                    const response = await axios.post('/api/cas/assets/create_text_asset', payload);
                } catch (error) {
                    const er = error.response;
                    if (er.status === 403 && er.data.insufficient_credits) {
                        alert_error(this.$t('new_asset.insufficient_credits'));
                        // since the user no longer has enough credits, we shouldn't try
                        // to upload the remaining files either
                        break;
                    } else {
                        alert_error(this.$t('message.general_server_error'));
                    }
                } finally {
                    this.loading = false;
                }
            }
            const assetDisplayNames = Array.from(this.candidateAssets)
                .map(ca => ca.info.displayName) // get asset display names first
                .map(dn => `"${dn}"`) // surround them with double quotes
                .join(', '); // create a comma-separated string
            toast_success(this.$t('new_asset.assets_created', {
                assets: assetDisplayNames
            }));
            this.files = this.files.filter(f => f !== ca.file);
            this.candidateAssets = this.candidateAssets.filter(asset => asset !== ca);
            this.$store.dispatch('get_mindcoin_balance');
            this.$emit("resetSearch");
            this.$emit("textUploadTriggered");
        },
    },
}
</script>

<style lang="scss" scoped>

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .popup-container-add {
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    // height: 85%;
    max-width: 1000px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

.upload-item-card {
    width: 1000px;
    max-width: 80vw;
    height: 800px;
    overflow: hidden;
    display: flex;
    flex-flow: column;
}

.upload-info .bottom-text {
    font-size: 1rem;
    font-family: 'Open Sans';
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
}

.upload-info .top-text {
    font-size: 1.675rem;
    font-family: 'Open Sans';
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;

}

.dialogs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.dialogs p {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 5px;
    font-weight: bolder;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.buttons button {
    margin: 20px 5px !important;
}

.button-blue {
    color: $mdspwhite_ui;
    background-color: $mdspblue_ui;
    min-width: 150px;
    margin-left: 10px;
}

.button-white {
    color: $mdspblue_ui;
    background-color: $mdspwhite_ui;
    min-width: 150px;
    margin-right: 10px;
}

.upload-list {
    height: 45%;
    overflow-y: auto;
    overflow-x: clip;
    margin-bottom: 10px;
}

.input-form {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 6px #0000007c;
    border: 1px solid #FFFFFF;
    border-radius: 41px;
    width: 60%;
}

.input-form input {
    width: calc(100% - 80px);
    padding-left: 32px;
    font-size: 1.5rem;
    font-family: "Open Sans";
    font-weight: 300;
    color: #000AFF;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
}

::placeholder {
    text-overflow: ellipsis;
}

.search-button {
    border-radius: 50%;
    background-color: blue;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}

.popup-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-button,
.next-button {
  background: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 0px; /* Spacing between buttons */
  transition: background 0.3s ease;
}

.next-button {
  /* If you want to differentiate the 'Next' button in some way, you can do it here */
  background: #7878e9; /* Slightly different shade, if desired */
}
.next-button:hover {
  /* If you want to differentiate the 'Next' button in some way, you can do it here */
  background: #3e3e86; /* Slightly different shade, if desired */
}
.back-button:hover {
  /* If you want to differentiate the 'Next' button in some way, you can do it here */
  background: #777777; /* Slightly different shade, if desired */
}

@media (min-width: 1024px) {
    .wrapper .input-form input {
        min-height: 50px;
    }

    .search-button {
        width: 50px;
        height: 50px;
    }
}

@media (min-width: 1600px) {
    .wrapper .input-form input {
        min-height: 60px;
    }

    .search-button {
        width: 60px;
        height: 60px;
    }
}

@media (min-width: 1920px) {
    .wrapper .input-form input {
        min-height: 80px;
    }

    .search-button {
        width: 80px;
        height: 80px;
    }
}
</style>
