const add_new_asset_translation = {
  en: {
    new_asset: {
      yes: 'YES',
      no: 'NO',
      add: 'ADD',
      option: 'OPTION',
      thank_you: 'THANK YOU!',
      coming_soon: 'Coming soon..',
      add_new_item: 'Add new concept(s)',
      choose_track: 'Choose your track',
      concept_upload: 'Concept(s) upload',
      upload_image: 'upload image/illustrations',
      upload_multimedia: 'upload videos/audio',
      add_words: 'Add keywords/slogans',
      calendar_title: 'Mindspeller Omnibus Survey Schedule',
      calendar_footer: 'Highlighted dates mark the release schedule for the Mindspeller Omnibus Survey. Submit your concepts by 16:00 CET to secure a spot—available on a first-come, first-served basis.',
      type_to_create_asset: 'Type to create keyword / slogan concept',
      word_exists_in_network:
        'This word has already been mapped before and is available for analysis in the apps!',
      duplicate_order_attempt: 'You have already ordered this specific word.',
      mapping_required: 'mapping required',
      total_mindcoins: 'Total: {total} mindcoins',
      insufficient_credits:
        'You do not have enough mindcoins to upload ' +
        'your uploaded concept(s). You can try removing some from the list.',
      upload_from_computer:
        'Please select the concepts you want to map. Our proprietary mapping process involves real people and their spontaneous associations with your concepts. The mapping process will be completed over the weekend and you will receive an email notification as soon as your concpets have been mapped.',
      short_notification_text:
        'You will receive an automatic e-mail notification as soon as your concept has been mapped.',
      answer_image_questions: 'Please complete all image classification tags',
      accepted_types: 'Accepted file types are: { types }.',
      drop_here: 'You can also drag and drop files here.',
      unacceptable_type: 'UNACCEPTABLE FILE TYPE. ONLY { types } ARE ALLOWED.',
      image_too_small: 'IMAGE IS TOO SMALL',
      is_item_branded: 'Is this showing a brand?',
      type_of_item: 'Type of item',
      upload_selected_asset: 'Upload selected concepts?',
      create_selected_asset: 'Order selected concepts?',
      this_will_cost: 'This will cost {price} mindcoins.',
      assets_created: 'Concept(s) {assets} uploaded, you will be notified when your ordered concepts are ready for analysis',
      public_concept: 'Make your concept(s) public with each 10% discount?',
      public_concept_info: 'If your concept is already published and you want to share its positioning within the Mindspeller community, click "Yes" for extra exposure and receive a 10% discount!',
      results_asap: 'Want to see results ASAP?',
      results_asap_info: 'Mindspeller omnibus surveys are conducted bi-weekly. Are you in a hurry or want to see results faster? Click "Yes" to explore our express options.',
      results_asap_eta: 'Need faster results? Choose the timeline that fits your needs:',
      surcharge: 'Surcharge',
      select: 'SELECT',
      final_checkout_1: 'Thank you for the upload!',
      final_checkout_2: 'Click "ADD" to checkout your asset to Mindspeller survey',
      final_checkout_no_mindcoins: 'You do not have enough mindcoins to checkout your concept.',
      back: 'BACK',
      next: 'NEXT',
      checkout: 'CHECKOUT',
      table_public_concept_title: 'Public Concept',
      add_new_asset_tooltip: 'Load your concepts to crowdsource human associations',
      concept_tag: 'Please make sure to fill the details and correct if necessary',
      eta_info: 'Simply select your preferred option at checkout and let us accelerate your journey to actionable insights!',
      eta_plan_1: 'Standard Delivery',
      eta_plan_2: 'Weekend Rush',
      eta_plan_3: 'Midweek Boost',
      eta_plan_4: 'Lightning Fast',
      eta_description_1: 'Results delivered by next omnibus survey.',
      eta_description_2: 'Get your results by the end of 1 weekend',
      eta_description_3: 'Results within the same week',
      eta_description_4: 'Insights ready within 2 days',
      track_1_info_a: 'Start crowdsourcing associations with your concepts using our bi-weekly “omnibus” survey. ',
      track_1_info_b: 'This survey ensures a statistically stable positioning of your concept in the network based on 100 to 200 online respondents who are instructed to enter 3 keyword associations for [10-20] randomized user-uploaded concepts (first in, first out).  ',
      track_1_info_c: 'Targeting attributes:',
      track_1_info_d: '- Language: English (US)',
      track_1_info_e: '- Gender: 50% male, 50% female',
      track_1_info_f: '- Age: 33% [18-36], 33% [37-45], 33% [46-64]',
      track_1_info_g: 'Check the upcoming omnibus deadline via the header icon.',
      track_2_info: 'Click on your preferred crowd-sourcing channel',
      track_3_info: 'Do you want to crowdsource associations for your concept from a specific panel? Share the sociodemographic characteristics of your target panel to receive a tailored quote.',
      track_2_submit: "Thank you for providing information about your preferred crowd-sourcing channel. We will get back to you soon.",
      track_2_title:"Click on your preferred crowd-sourcing channel",


    },
  },
  nl: {
    new_asset: {
      yes: 'JA',
      no: 'NEE',
      add: 'TOEVOEGEN',
      option: 'OPTIE',
      thank_you: 'Bedankt!',
      coming_soon: 'Binnenkort beschikbaar..',
      add_new_item: 'Nieuwe concept(en) toevoegen',
      choose_track: 'Kies je traject',
      concept_upload: 'Concept(s) uploaden',
      upload_image: 'Afbeelding/illustraties uploaden',
      upload_multimedia: 'upload multimedia',
      add_words: 'voeg woord(en) toe',
      calendar_title: 'Schema voor de Mindspeller Omnibus-enquête',
      calendar_footer:'Gemarkeerde data geven het releaseschema aan voor de Mindspeller Omnibus Survey. Dien je concepten in vóór 16:00 CET om een plaats te reserveren—beschikbaar op basis van wie het eerst komt, het eerst maalt.',
      type_to_create_asset: 'Type om keyword/slogan concept te creeren',
      word_exists_in_network:
        'Dit woord werd al eerder gemapped en is beschikbaar voor analyse in de apps',
      duplicate_order_attempt: 'Je hebt dit woord al besteld.',
      mapping_required: 'mapping survey nodig',
      total_mindcoins: 'Totaal: {total} Mindcoins',
      insufficient_credits:
        'Je hebt onvoldoende Mindcoins om al deze' +
        'Concepten toe te voegen. Probeer om enkele concepten te verwijderen van de lijst.',
      upload_from_computer:
        'Gelieve uw Concept te selecteren voor mapping. Gezien we uw Concept mappen door bevraging van spontane associaties bij echte mensen, kan het mappen even duren.\n U ontvangt een automatische e-mail notificatie zodra uw Concept gemapt werd.',
      short_notification_text:
        'U ontvangt een automatische e-mail notificatie zodra uw concept gemapt werd.',
      answer_image_questions:
        'Gelieve alle gevraagde labels m.b.t. de afbeeldingen te vervolledigen',
      accepted_types: 'Aanvaarde bestand types zijn: { types }.',
      drop_here: 'Je kan ook bestanden naar hier slepen.',
      unacceptable_type:
        'ONAANVAARDBAARE BESTAND TYPE. ENKEL { types } TOEGESTAAN.',
      image_too_small: 'Afbeelding te klein',
      is_item_branded: 'Toont dit een merk?',
      type_of_item: 'Item type',
      upload_selected_asset: 'Geselecteerde concepten opladen?',
      create_selected_asset: 'Geselecteerde concepten bestellen?',
      this_will_cost: 'Dit bedraagt {price} Mindcoins.',
      assets_created: 'Gecreëerde concepten: {assets}.',
      public_concept: 'Maak uw concept(en) openbaar met 10% korting per stuk?',
      public_concept_info: 'Is je concept al gepubliceerd en wil je de positionering ervan delen binnen de Mindspeller-community? Klik dan op "Ja" voor extra exposure en ontvang een korting van 10%!',
      results_asap: 'Wil je zo snel mogelijk resultaten zien?',
      results_asap_info: 'Mindspeller-omnibusenquêtes worden tweewekelijks uitgevoerd. Heb je haast of wil je sneller resultaten? Klik op "Ja" om onze expressopties te bekijken.',
      results_asap_eta: 'Snellere resultaten nodig? Kies de tijdlijn die bij uw behoeften past:',
      surcharge: 'Toeslag',
      select: 'SELECTEER',
      final_checkout_1: 'Bedankt voor het uploaden!',
      final_checkout_2: 'Klik op "TOEVOEGEN" om uw asset aan de Mindspeller-enquête toe te voegen.',
      final_checkout_no_mindcoins: 'U heeft niet genoeg mindcoins om uw concept af te rekenen.',
      back: 'TERUG',
      next: 'VOLGENDE',
      checkout: 'AFREKENEN',
      table_public_concept_title: 'Openbaar concept',
      add_new_asset_tooltip: 'Laad uw concepten om menselijke associaties te crowdsourcen',
      concept_tag: 'Zorg ervoor dat u de gegevens invult en corrigeer ze indien nodig',
      eta_info: 'Selecteer eenvoudig uw voorkeursoptie bij het afrekenen en laat ons uw reis naar bruikbare inzichten versnellen!',
      eta_plan_1: 'Standaard levering',
      eta_plan_2: 'Weekend spoedlevering',
      eta_plan_3: 'Midweekversnelling',
      eta_plan_4: 'Bliksemsnel',
      eta_description_1: 'Resultaten geleverd door volgende omnibusonderzoek.',
      eta_description_2: 'Ontvang uw resultaten aan het einde van één weekend',
      eta_description_3: 'Resultaten binnen dezelfde week',
      eta_description_4: 'Inzichten gereed binnen 2 dagen',
      track_1_info_a: 'Start met het crowdsourcen van associaties met jouw concepten via onze tweewekelijkse "omnibus"-enquête.',
      track_1_info_b: 'Deze enquête zorgt voor een statistisch stabiele positionering van jouw concept in het netwerk, gebaseerd op 100 tot 200 online respondenten die de opdracht krijgen om 3 trefwoordassociaties in te voeren voor [10-20] willekeurig geselecteerde, door gebruikers geüploade concepten (first in, first out).',
      track_1_info_c: 'Targeting-kenmerken:',
      track_1_info_d: '- Taal: Dutch (Belgian)',
      track_1_info_e: '- Geslacht: 50% man, 50% vrouw',
      track_1_info_f: '- Leeftijd: 33% [18-36], 33% [37-45], 33% [46-64]',
      track_1_info_g: 'Controleer de deadline voor de komende omnibus via het icoon in de header.',
      track_2_info: 'Klik op uw voorkeurskanaal voor crowdsourcing',
      track_3_info: 'Wil je je concept laten associëren door een specifiek panel? Deel de sociodemografische kenmerken van je doelpanel voor een op maat gemaakte offerte.',
      track_2_submit: ' Bedankt voor het verstrekken van informatie over uw voorkeurskanaal voor crowdsourcing. We nemen binnenkort contact met u op.',
      track_2_title: 'Klik op uw favoriete crowdsourcing-kanaal',
    },
  },
};

export { add_new_asset_translation };
