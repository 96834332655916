<template>
  <q-item justify-center>
    <q-item-section class="delete" side>
      <div class="text-grey-8 q-gutter-xs">
        <q-btn v-if="!uploading" flat dense round class="gt-xs" size="12px" icon="delete" @click="remove">
        </q-btn>
        <q-circular-progress v-if="uploading" indeterminate size="50px" color="lime" class="q-ma-md">
        </q-circular-progress>
      </div>
      <!-- here we toggle the asset uploaded from the user from confidential to public -->
      <!-- <q-toggle dense size="28px" label="Confidential" v-model="questions.isConfidential" /> -->
    </q-item-section>

    <q-item-section avatar class="q-mr-md" style="margin-left: 0px;">
      <font-awesome-icon icon="exclamation" v-if="!info.acceptable" style="font-size: 70px;">
      </font-awesome-icon>
      <div v-if="info.acceptable">
        <img v-if="info.type === 'image'" :src="imageInfo.src" class="candidate-asset-img">
        <font-awesome-icon icon="volume-up" v-if="info.type === 'audio'" style="font-size: 70px;">
        </font-awesome-icon>
        <font-awesome-icon icon="video" v-if="info.type === 'video'" style="font-size: 70px;">
        </font-awesome-icon>
        <font-awesome-icon icon="font" v-if="info.type === 'word'" style="font-size: 70px;">
        </font-awesome-icon>
      </div>
    </q-item-section>

    <div class="input-dialog">
      <div>
        <q-input v-model="info.displayName"  :readonly="!info.acceptable">
        </q-input>
      </div>
      <!-- <div v-if="info.type == 'unacceptable' || info.type == 'image'" class="dialog">
        <p v-if="info.type === 'unacceptable'">
          {{ $t("new_asset.accepted_types", { types: extensionsHumanReadable }) }}
        </p>
        <p v-if="info.type === 'image' && imageInfo.tooSmall">
          {{ $t("new_asset.image_too_small") }}
        </p>
      </div> -->
    </div>

    <span class="optional-info" v-if="fullSize" style="margin-left: 20px;">
      <div class="item-branded" v-if="info.type === 'image' && info.acceptable">
        <div class="text-weight-bold radio-label">
          {{ $t('new_asset.is_item_branded') }}
        </div>
        <div class="radio-container">
          <q-radio dense class="radio" v-model="questions.isBrand" val="1" :label="$t('message.yes')" />
          <q-radio dense class="radio" v-model="questions.isBrand" val="0" :label="$t('message.no')" />
        </div>
      </div>
      <div class="item-type" v-if="info.type === 'image' && info.acceptable">
        <div class="text-weight-bold radio-label">
          {{ $t('new_asset.type_of_item') }}
        </div>
        <div class="radio-container">
          <q-radio dense class="radio" v-model="questions.type" val="logo" :label="$t('message.logo')" />
          <q-radio dense class="radio" v-model="questions.type" val="photo" :label="$t('message.photo')" />
          <q-radio dense class="radio" v-model="questions.type" val="other" :label="$t('message.other')">
          </q-radio>
        </div>
      </div>
      <div class="item-price" v-if="info.acceptable" style="margin-right: 70px;">
        <div class="text-weight-bold price">{{ info.price }} mindcoins</div>
        <div class="text-weight-bold mapping">
          {{ $t('new_asset.mapping_required') }}
        </div>
      </div>
    </span>
  </q-item>
</template>

<script>
import { ref } from 'vue'
export default {
  setup() {
  },
  emits: ['remove'],
  props: {
    file: { required: true, type: File },
    extensions: { default: ['png', 'jpg', 'jpeg', 'mp3', 'mp4'], type: Array },
    minimumImageWidth: { default: 300, type: Number },
    minimumImageHeight: { default: 300, type: Number },
    fullSize: { default: true, type: Boolean }
  },
  data: function () {
    return {
      uploading: false,
      info: {
        displayName: this.file.name.replace(/\.[^/.]+$/, ""),
        // an asset is unacceptable if it's not one of the supported extensions,
        // or if it's an image and it's too small
        acceptable: true,
        type: this._getFileType(this.file),
        price: 0,
      },
      // only images have questions; questions are unanswered (null) at first
      questions: {
        isBrand: this.file.brand,
        type: this.file.logo,
      },
      /*
      Contains additional info about an image file (filled in on mount). If the
      provided file is not an image imageInfo is meaningless and shouldn't be
      used.
      */
      imageInfo: {
        width: 0,
        height: 0,
        tooSmall: false,
        src: '',
      }
    }
  },
  computed: {
    /*
    Whether image questions have been answered. It is meaningless for non-image
    candidate assets.
    */
    bothQuestionsAnswered: function () {
      if (this.questions.isBrand !== null && this.questions.type !== null) {
        return true;
      }
    },
    questionsAnswered: function () {
      if (this.imageInfo.tooSmall) {
        return true;
      }
      // return 'true' only if both isBrand and type are not null (they are null
      // if the question hasn't been answered)
      return this.questions.isBrand !== null && this.questions.type !== null;
    },
    extensionsHumanReadable: function () {
      return this.extensions.map(e => `*.${e.toUpperCase()}`).join(', ');
    },
  },
  methods: {
    remove: function () {
      this.$emit('remove', this.file);
    },
    _getFileType: function (file) {
      const extension = file.name.split('.').pop().toLowerCase();
      switch (extension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
          return 'image';
        case 'mp4':
          return 'video';
        case 'mp3':
          return 'audio';
        case 'txt':
          return 'word';
        default:
          return 'unacceptable';
      }
    },
    _getPrice: function (file) {
      let priceType = '';
      let price = 0;
      const type = this._getFileType(file);
      switch (type) {
        case 'image':
          priceType = 'private_image_asset';
          break;
        case 'audio':
          priceType = 'private_av_asset';
          break;
        case 'video':
          priceType = 'private_av_asset';
          break;
        case 'word':
          priceType = 'private_word_asset';
          break;
      }
      if (priceType !== '') {
        price = this.$store.getters.get_price(priceType);
      }
      return price;
    },
  },
  mounted: async function () {
    // check if this asset is acceptable (asset is acceptable if it's extension
    // is one of the ones we support and if the image is not too small (in case
    // this asset is an image)
    if (this.info.type !== 'word') {
      const extension = this.file.name.split('.').pop().toLowerCase();
      this.info.acceptable = this.extensions.includes(extension);
    }

    // if this asset is an image get the image specific information
    if (this.info.type === 'image') {
      // create a DOMString that will serve as the <img> src attribute
      const src = URL.createObjectURL(this.file);
      // create an Image object based on the DOMString; used to get dimensions
      const image = new Image;
      image.src = src;
      // wait for the image to load from the provided source
      await image.decode();
      this.imageInfo.width = image.width;
      this.imageInfo.height = image.height;
      this.imageInfo.src = image.src;
      this.imageInfo.tooSmall =
        image.width < this.minimumImageWidth &&
        image.height < this.minimumImageHeight;
      // images that are too small aren't acceptable
      if (this.imageInfo.tooSmall) {
        this.info.acceptable = false;
      }
    }

    // load the price from the store
    this.info.price = this._getPrice(this.file);
  },
}
</script>

<style scoped lang="scss">
.candidate-asset-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.detail-selector {
  display: flex;
  flex-wrap: wrap;
}

.detail-selector .text {
  flex-basis: 100%;
}

.radio-container {
  display: flex; /* Enable Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  gap: 10px; /* Add space between the radio buttons */
  margin: 0 auto; /* Center the container itself if needed */
}
.radio {
  
  margin: 0 5px 0 0;
}

.price {
  font-size: 1.125rem;
}

mapping {
  color: $mdspblue_ui;
}

.item-type {
  margin-left: 10px;
  width: 60%;
}

.item-price {
  margin-left: 0px;
  width: 17%;
}

.radio-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin-bottom: 10px;
  padding: 10px 0 0 10px;
}

.input-dialog {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  min-width: 130px;
  max-width: 15%;
  gap: 10px;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
}

.input-dialog .dialog {
  flex-basis: auto;
  flex-grow: 1;
}

.input-dialog .dialog p {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem;
  color: #ff4d4d;
  font-style: italic;
  line-height: 1.2;
}

@media (max-width: 768px) {
  .input-dialog {
    width: 100%;
  }
}

.optional-info {
  display: flex;
  padding-left: 0;
  width: 69%;
}

.text-weight-bold {
  font-weight: 700;
  font-size: 12px;
}

.q-item__section {
  width: 97px;
  margin-right: 0;
}

.q-item__section.delete {
  width: 10px;
}

.q-radio__label.q-anchor--skip {
  padding-left: 0;
}

.item-branded {
  width: 32%;
}
</style>
