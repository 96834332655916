<template>
    <section class="middle-section">
        <div class="container">
            <div class="subsc-pg2">
                <div class="col-md-12 result-text">
                    <div v-if="showBackButton" style="display: flex; justify-content: flex-start;">
                        <btn style="background-color:#92a0b3 !important; font-size: 1.3vw;" class="button_pay"
                            @click="route2Brand" v-close-popup>Back</btn>
                    </div>
                    <div class="headingbar">Mindspeller Business Model</div>
                    <div class="headingbar" style="font-size: 15px; margin-bottom: 0;">
                        Add credits (mindcoins) to test your concepts
                    </div>
                </div>
                <!-- <div class="licenses_container">
                    <q-card class="licence_container">
                        <div class="licence_label_container">Pay as you go</div>
                        <div class="licence_price_container">Free</div>
                        <div class="licence_detail_container">Unlimited access demo license</div>
                        <div class="licence_item_container">
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>90 credits</div>
                            </div>
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>US English panel</div>
                            </div>
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Test community assets</div>
                            </div>
                        </div>
                        <div class="subscribe_container">
                            <a @click="handleStartForFree" class="button">Start for Free<span class="arrow">→</span></a>
                        </div>
                    </q-card>
                    <q-card class="licence_container">
                        <div class="licence_label_container">Do it yourself</div>
                        <div class="licence_price_container">&euro; 49 / month</div>
                        <div class="licence_detail_container">Save one month on annual plan</div>
                        <div class="licence_item_container">
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>150 credits per month</div>
                            </div>
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Other panel options</div>
                            </div>
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Support options</div>
                            </div>
                        </div>
                        <div class="subscribe_container">
                            <a @click="changePlan(diyLicense)" class="button">Do it myself<span class="arrow">→</span></a>
                        </div>
                    </q-card>
                    <q-card class="licence_container">
                        <div class="licence_label_container">Do it for me</div>
                        <div class="licence_price_container">&euro; 495</div>
                        <div class="licence_detail_container">Starting price</div>
                        <div class="licence_item_container">
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Customize your panel</div>
                            </div>
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Brand positioning</div>
                            </div>
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Mindspeller insights</div>
                            </div>
                        </div>
                        <div class="subscribe_container">
                            <a @click="handleContactSales" class="button">Do it for me<span class="arrow">→</span></a>
                        </div>
                    </q-card>
                </div> -->
                <div class="licence_container" style="width: 1000px;">
                    <div style="display: flex; gap:50px; width:100%;">
                        <div style="display: flex; flex-direction: column; width: 30%; gap:10px; margin-left: 10px;">
                            <div class="licence_label_container">Mindcoin Credit Model</div>
                            <div class="licence_detail_container">&dollar; 1 per Mindcoin for English (US) Panel</div>
                            <div v-if="this.currentlang === 'nl'" class="licence_detail_container">&euro; 1.5 per Mindcoin for Dutch (Belgian) Panel</div>
                            <div class="licence_item_container">
                                <div style="display: flex; gap: 25px; align-items: center;">
                                    <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                    <div>Browse Mindspeller’s community library of benchmark concepts</div>
                                </div>
                                <div style="display: flex; gap: 25px; align-items: center;">
                                    <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                    <div>Mindspeller standard US/EU panel</div>
                                </div>
                                <div style="display: flex; gap: 25px; align-items: center;">
                                    <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                    <div>Custom panels upon request </div>
                                </div>
                            </div>
                            <div class="subscribe_container_mindcoin_model" style="justify-content: flex-start;">
                                <a @click="prompt = true" class="button">Add Credits<span class="arrow">→</span></a>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: column; width: 60%; gap:10px; justify-content: center; align-items: center; margin-top: 10px; margin-left: 10px;">
                            <p>Investment In Mindcoins Required To</p>
                            <table class="custom-table">
                                <tr>
                                    <td style="border: none;" rowspan="2"></td>
                                    <td style="border: none;" rowspan="2">Mindspeller Library</td>
                                    <td style="border: none;" colspan="3">Omnibus Panel</td>
                                </tr>
                                <tr>
                                    <td style="border: none;">English (US)</td>
                                    <td v-if="this.currentlang === 'nl'" style="border: none;">Dutch (Belgian)</td>
                                </tr>
                                <tr>
                                    <td>Keyword / slogan</td>
                                    <td>10</td>
                                    <td>100</td>
                                    <td v-if="this.currentlang === 'nl'">150</td>
                                </tr>
                                <tr>
                                    <td>Logo / image</td>
                                    <td>20</td>
                                    <td>200</td>
                                    <td v-if="this.currentlang === 'nl'">300</td>
                                </tr>
                                <tr>
                                    <td>Audio / video</td>
                                    <td>30</td>
                                    <td>300</td>
                                    <td v-if="this.currentlang === 'nl'">450</td>
                                </tr>
                            </table>
                            <!-- <div v-if="this.currentlang === 'en'">You are currently in the EN panel. Click 
                            <a href="https://cas-nl.mindspeller.com/#/login" target="_blank" style="color: #FFD700; text-decoration: underline;">this link</a>
                            to access the NL Panel.
                            </div> -->
                            <div  v-if="this.currentlang === 'nl'">You are currently in the NL panel. Click 
                            <a href="https://cas-en.mindspeller.com/#/login" target="_blank" style="color: #FFD700; text-decoration: underline;">this link</a>
                            to access the EN Panel.
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style="text-align: center; font-weight: bolder;background-color: #E3E9FD; padding:15px;width: 70%;margin: auto;font-size: small;">
                    <router-link :to="{ name: 'Library_Mindspeller', params: {active: '' }}" target='_blank'>Mindspeller Library Benchmark-Concepts</router-link>
                    require 10x less mindcoins than Self-Concepts!
                </div>
                <!-- <div class="col-md-12 result-text" style="margin-top: 50px;">Do it for me Reports</div>
                <div class="licenses_container" style="margin-top: 10px;">
                    <q-card class="licence_container" style="height: 350px;">
                        <div class="licence_label_container" style="margin-left: 25px;">Do it for me <br>Concept Test</div>
                        <div class="licence_price_container" style="margin-left: 25px;">&euro; 495 / report </div>
                        <div class="licence_detail_container" style="margin-left: 25px;">Starting price*</div>
                        <div class="licence_item_container" style="margin-left: 25px; margin-top: 25px;">
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>A/B test results</div>
                            </div>
                            <div></div>
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Insights</div>
                            </div>
                            <div></div>
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Recommendations</div>
                            </div>
                        </div>
                        <div class="subscribe_container">
                            <a @click="handleContactSales" class="button">Contact Sales<span class="arrow">→</span></a>
                        </div>
                        <div style="text-align: center; margin-top: 10px; font-size: 12px;">
                            *Final price depends on panel and/or report customization 
                        </div>
                    </q-card>
                    <q-card class="licence_container" style="height: 350px;">
                        <div class="licence_label_container" style="margin-left: 25px;">Do it for me<br>Brand Positioning</div>
                        <div class="licence_price_container" style="margin-left: 25px;">&euro; 1495 / report</div>
                        <div class="licence_detail_container" style="margin-left: 25px;">Starting price*</div>
                        <div class="licence_item_container" style="margin-left: 25px; margin-top: 25px;">
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Up to 6 brand assets</div>
                            </div>
                            <div></div>
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Insights</div>
                            </div>
                            <div></div>
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Recommendations</div>
                            </div>
                        </div>
                        <div class="subscribe_container">
                            <a @click="handleContactSales" class="button">Contact Sales<span class="arrow">→</span></a>
                        </div>
                        <div style="text-align: center; margin-top: 10px; font-size: 12px;">
                            *Final price depends on panel and/or report customization 
                        </div>
                    </q-card>
                    <q-card class="licence_container" style="height: 350px;">
                        <div class="licence_label_container" style="margin-left: 25px;">Do it for me<br>Brand Development</div>
                        <div class="licence_price_container" style="margin-left: 25px;">&euro; 2995 / report</div>
                        <div class="licence_detail_container" style="margin-left: 25px;">Starting price*</div>
                        <div class="licence_item_container" style="margin-left: 25px; margin-top: 25px;">
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Up to 10 brand concepts</div>
                            </div>
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Insights</div>
                            </div>
                            <div style="display: flex; gap: 25px; align-items: center;">
                                <div><font-awesome-icon :icon="['fas', 'check']" style="color: #ffffff;" /></div>
                                <div>Recommendations</div>
                            </div>
                        </div>
                        <div class="subscribe_container" style="margin-top: 20px;">
                            <a @click="handleContactSales" class="button">Contact Sales<span class="arrow">→</span></a>
                        </div>
                        <div style="text-align: center; margin-top: 10px; font-size: 12px;">
                            *Final price depends on panel and/or report customization 
                        </div>
                    </q-card>
                </div> -->
                <!-- we create modal for different options -->
                <!-- this is the modal for pay as you go -->
                <q-dialog v-model="prompt" persistent>
                    <q-card style="max-width: 800px">
                        <div style="display: flex;justify-content: flex-end;">
                            <q-btn round flat icon="close" class="q-mr-md" @click="closeDialog" />
                        </div>
                        <q-card-section>
                            <div class="q-gutter-md">
                                <h6 class="text-h6" style="text-align: center; font-weight: bolder; margin-top: -1vw;">
                                    Add Credits
                                </h6>
                                <div
                                    style="text-align: center; color: #2d2e2e; font-weight: bold; font-size: small;margin-top: -0.3vw;">
                                    Specify the quantity and variety of creative concepts you wish to survey, map
                                    and
                                    test. <br>
                                    <strong>Tip:</strong> ensure you order enough concepts, including benchmarks like
                                    competitor logos,
                                    slogans, websites, hallmarks, key visuals, multimedia clips, for your study.
                                </div>
                                <div>
                                    <table class="payment-table"
                                        style="color: #616364; font-size: smaller; margin-top: -0.3vw;">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th><strong>Mindcoins needed for Self-Concept survey & mapping</strong></th>
                                                <th><strong>Number of Self-Concepts you want to survey & map</strong></th>
                                                <th>Total amount of mindcoins required</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Text Concepts (keyword, slogan,..)</td>
                                                <td>
                                                    <div
                                                        style="display: flex; justify-content:center; align-items: center;">
                                                        <strong>100 mindcoins</strong>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        style="display: flex; justify-content: center; align-items: center;">
                                                        <div style="width: 100px;margin-left: 20px;">
                                                            <q-input v-model="private_word_asset" outlined type="number"
                                                                dense pattern="[0-9]*" min="0" />
                                                        </div>
                                                        <q-stepper-buttons v-model="inputValue" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <strong>
                                                        {{ 100 * private_word_asset }} mindcoins
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Image Concepts (logo, key visual,..)</td>
                                                <td>
                                                    <div
                                                        style="display: flex; justify-content: center; align-items: center;">
                                                        <strong>200 mindcoins</strong>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        style="display: flex; justify-content: center; align-items: center;">
                                                        <div style="width: 100px;margin-left: 20px;">
                                                            <q-input v-model="private_image_asset" outlined type="number"
                                                                dense pattern="[0-9]*" min="0" />
                                                        </div>
                                                        <q-stepper-buttons v-model="inputValue" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <strong>
                                                        {{ 200 * private_image_asset }} mindcoins
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Multimedia Concepts (video, gif, jingle,.. )</td>
                                                <td>
                                                    <div
                                                        style="display: flex; justify-content: center; align-items: center;">
                                                        <strong>300 mindcoins</strong>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        style="display: flex; justify-content: center; align-items: center;">
                                                        <div style="width: 100px;margin-left: 20px;">
                                                            <q-input v-model="private_media_asset" outlined type="number"
                                                                dense pattern="[0-9]*" min="0" />
                                                        </div>
                                                        <q-stepper-buttons v-model="inputValue" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <strong>
                                                        {{ 300 * private_media_asset }} mindcoins
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </q-card-section>
                        <q-card-section class="q-pt-none">
                            <div
                                style="margin-top: 20px;font-size: smaller; display: flex;justify-content: center; margin: auto;">
                                <div style=" margin-right: 30px;">
                                    <q-checkbox v-model="isChecked" dense />
                                </div>
                                <span>I would like to increase my mindcoins with 20% to add <router-link :to="{ name: 'Library_Mindspeller', params: {active: '' }}" target='_blank'>
                                    Mindspeller Library Benchmark-Concepts</router-link> to my
                                    research</span>
                            </div>
                            <div
                                style="display: flex; justify-content: center; align-items: center; margin: 20px 0;font-size: small;">
                                <!-- <span> Assets &nbsp;= &nbsp;</span> -->
                                <div style="width: 150px;">
                                    <q-input v-model="amount_mindcoin" outlined type="number" dense pattern="[0-9]*" />
                                    <q-stepper-buttons />
                                </div>
                                &nbsp; mindcoins required for your order &nbsp;
                                <span v-if="this.currentlang === 'en'"> &nbsp;= &nbsp;{{ (amount_mindcoin / 1).toFixed(0) }} € investment</span>
                                <span v-else> &nbsp;= &nbsp;{{ (amount_mindcoin * 1.5).toFixed(0) }} € investment</span>
                            </div>
                            <div style="color: #2d2e2e; text-align: center;font-size: small;">
                                Update your order by entering the number of Concepts you want to test/order. <br>
                                The required number of mindcoins will be updated.
                            </div>

                        </q-card-section>
                        <div
                            style="text-align: center; font-weight: bolder;background-color: #E3E9FD; padding:15px;width: 95%;margin: auto;font-size: small;">
                            <router-link :to="{ name: 'Library_Mindspeller', params: {active: '' }}" target='_blank'>Mindspeller Library Benchmark-Concepts</router-link>
                            require 10x less mindcoins than Self-Concepts!
                        </div>
                        <q-card-actions class="q-mb-md">
                            <div flat style="display: flex;justify-content: flex-end;margin-top: 0.1vw;">
                                <btn style="background-color:#92a0b3 !important; margin-right: 1vw;" class="button_pay"
                                    @click="closeDialog" v-close-popup>Cancel​</btn>
                                <btn class="button_pay" v-close-popup @click="handlePayAsYouGo">Confirm​</btn>
                            </div>
                        </q-card-actions>
                    </q-card>
                </q-dialog>

                <!-- this is the modal for diy subscriotion -->
                <q-dialog v-model="prompt_diy" persistent>
                    <q-card style="max-width: 700px;">
                        <div style="display: flex;justify-content: flex-end;">
                            <q-btn round flat icon="close" class="q-mr-md" @click="closeDialog_diy" />
                        </div>
                        <q-card-section>
                            <div style="display: flex; justify-content: center; padding: 30px;">
                                <!-- here we put the 3 subscription options -->
                                <q-card class="my-card" style="margin-right: 2vw;">
                                    <q-card-section class="bg-grey-8 text-white">
                                        <div class="text-h6" style="text-align: center; font-weight: bolder;">Bronze</div>
                                        <div class="text-subtitle2" style="text-align: center;">3 x $99.99</div>
                                    </q-card-section>
                                    <q-card-actions vertical>
                                        <div style="margin: 1vw 0; margin-top: 1vw;">
                                            <div class="text-subtitle2" style="text-align: center; color:#616364;">
                                                <strong>1€ = 2.5 mincoincs</strong>
                                            </div>
                                            <div class="text-subtitle2" style="text-align: center; color: #616364;">(min.
                                                <strong>3 month</strong> subscription)
                                            </div>
                                        </div>
                                        <div flat v-if="this.$store.state.current_license.id != 23"
                                            style="display: flex;justify-content: center;margin-top: 3vh;">
                                            <btn class="button_pay" @click="changePlan(bronzeLicense)">Upgrade</btn>
                                        </div>
                                        <div v-else flat style="display: flex;justify-content: center;margin-top: 3vh;">
                                            <btn class="button_pay" style="background-color: #92a0b3;">Bronze</btn>
                                        </div>
                                    </q-card-actions>
                                </q-card>

                                <q-card class="my-card" style="margin-right: 2vw;">
                                    <q-card-section class="bg-grey-8 text-white">
                                        <div class="text-h6" style="text-align: center; font-weight: bolder;">Silver</div>
                                        <div class="text-subtitle2" style="text-align: center;">3 x $499.99</div>
                                    </q-card-section>
                                    <q-card-actions vertical>
                                        <div style="margin: 1vw 0; margin-top: 1vw;">
                                            <div class="text-subtitle2" style="text-align: center;color: #616364;">
                                                <strong>1€ = 2.5 mincoincs</strong>
                                            </div>
                                            <div class="text-subtitle2" style="text-align: center;color: #616364;">(min.
                                                <strong>3 month</strong> subscription)
                                            </div>
                                        </div>
                                        <div flat v-if="this.$store.state.current_license.id != 24"
                                            style="display: flex;justify-content: center;margin-top: 3vh;">
                                            <btn class="button_pay" @click="changePlan(silverLicense)">Upgrade</btn>
                                        </div>
                                        <div v-else flat style="display: flex;justify-content: center;margin-top: 3vh;">
                                            <btn class="button_pay" style="background-color: #92a0b3;">Silver</btn>
                                        </div>
                                    </q-card-actions>
                                </q-card>

                                <q-card class="my-card">
                                    <q-card-section class="bg-grey-8 text-white">
                                        <div class="text-h6" style="text-align: center; font-weight: bolder;">Gold</div>
                                        <div class="text-subtitle2" style="text-align: center;">3 x $999.99</div>
                                    </q-card-section>
                                    <q-card-actions vertical>
                                        <div style="margin: 1vw 0; margin-top: 1vw;">
                                            <div class="text-subtitle2" style="text-align: center;color: #616364;">
                                                <strong>1€ = 2.5 mincoincs </strong>
                                            </div>
                                            <div class="text-subtitle2" style="text-align: center;color: #616364;">(min.
                                                <strong>3 month</strong> subscription)
                                            </div>
                                        </div>
                                        <div v-if="this.$store.state.current_license.id != 25" flat
                                            style="display: flex;justify-content: center;margin-top: 3vh;">
                                            <btn class="button_pay" @click="changePlan(goldLicense)">Upgrade</btn>
                                        </div>
                                        <div v-else flat
                                            style="display: flex;justify-content: center;margin-top: 3vh; background-color: #92a0b3;">
                                            <btn class="button_pay" style="background-color: #92a0b3;">Gold</btn>
                                        </div>
                                    </q-card-actions>
                                </q-card>
                            </div>
                        </q-card-section>
                    </q-card>
                </q-dialog>

            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import {
    alert_error,
    genericDialog,
    toast_success
} from '@/helpers/alert_helper.js';


export default {
    name: 'SubscriptionPlans',
    props:
    {
        charge_amount: {
            type: Number,
            default: 0
        },
        showPrompt: {
            type: Boolean,
            default: false
        },
        ifBack: {
            type: Boolean,
            default: false
        }

    },

    data() {
        return {
            isChecked: true,
            showFirstTable: true,
            showBackButton: false,
            bronzeLicense: {
                "amount": 495,
                "co_limit_characters": 100,
                "credits": 1000,
                "credits_on_assignment": 1000,
                "id": 23,
                "is_demo": 0,
                "is_legacy": 0,
                "is_purchasable": 1,
                "mindcoins": 1000,
                "monthly_fee": 495,
                "name": "Bronze",
                "pay_as_you_go": 0,
                "py/object": "shared.flask_models.models.License.License"
            },
            silverLicense: {
                "amount": 985,
                "co_limit_characters": 300,
                "credits": 2000,
                "credits_on_assignment": 2000,
                "id": 24,
                "is_demo": 0,
                "is_legacy": 0,
                "is_purchasable": 1,
                "mindcoins": 2000,
                "monthly_fee": 985,
                "name": "Silver",
                "pay_as_you_go": 0,
                "py/object": "shared.flask_models.models.License.License"
            },
            goldLicense: {
                "amount": 1980,
                "co_limit_characters": 400,
                "credits": 4000,
                "credits_on_assignment": 4000,
                "id": 25,
                "is_demo": 0,
                "is_legacy": 0,
                "is_purchasable": 1,
                "mindcoins": 4000,
                "monthly_fee": 1980,
                "name": "Gold",
                "pay_as_you_go": 0,
                "py/object": "shared.flask_models.models.License.License"
            },
            diyLicense: {
                "amount": 49,
                "co_limit_characters": 280,
                "credits": 150,
                "credits_on_assignment": 150,
                "id": 27,
                "is_demo": 0,
                "is_legacy": 0,
                "is_purchasable": 1,
                "mindcoins": 150,
                "monthly_fee": 49,
                "name": "150 credits per month per user ",
                "pay_as_you_go": 0,
                "py/object": "shared.flask_models.models.License.License"
            },

            amount_mindcoin: 0,
            amount: 0,
            private_word_asset: 3,
            private_image_asset: 3,
            private_media_asset: 3,
            // public_word_asset: 1,
            // public_image_asset: 1,
            // public_media_asset: 1,
            allLicences: [],
            selectedLicense: null,
            price: '',
            usdToEurRate: null,
        };
    },
    created() {
        let defapp_lang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE');
        this.currentlang = defapp_lang;

    },
    setup() {
        return {
            prompt: ref(false),
            prompt_diy: ref(false),
        }
    },
    mounted: async function () {
        const res = await axios.get('https://v6.exchangerate-api.com/v6/2d44e04850226e23380de8a2/latest/USD')
            .then(response => {
                this.usdToEurRate = response.data.conversion_rates.EUR
            })
            .catch(error => {
                console.error('Error fetching exchange rate:', error);
            });
        this.amount_mindcoin = 1.2 * (100 * this.private_word_asset +
            200 * this.private_image_asset +
            300 * this.private_media_asset);
        this.amount = 1.2 * (100 * this.private_word_asset +
            200 * this.private_image_asset +
            300 * this.private_media_asset) / 2;
        document.getElementById('mindspeller').style.overflow = "auto";
        axios.get('/api/cas/org/license/all').then(
            (response) =>
            (this.allLicences = response.data
                .filter((license) => !license.is_legacy)
                .filter(
                    (license) => license.id !== this.$store.state.current_license.id
                )
                .filter((license) => license.is_purchasable == 1))
        );
    },
    methods: {
        updateMindcoins() {
            if (this.isChecked) {
                this.amount_mindcoin = 1.2 * this.amount_mindcoin;
                this.amount = 1.2 * this.amount;
            } else {
                this.amount_mindcoin = this.amount_mindcoin / 1.2;
                this.amount = this.amount / 1.2;
            }
        },

        calculateAmountMindcoin() {
            if (this.isChecked) {
                this.amount_mindcoin = 1.2 * (100 * this.private_word_asset +
                    200 * this.private_image_asset +
                    300 * this.private_media_asset);
                this.amount = 1.2 * (100 * this.private_word_asset +
                    200 * this.private_image_asset +
                    300 * this.private_media_asset) / 2;
            } else {
                this.amount_mindcoin = 100 * this.private_word_asset +
                    200 * this.private_image_asset +
                    300 * this.private_media_asset;
                this.amount = (100 * this.private_word_asset +
                    200 * this.private_image_asset +
                    300 * this.private_media_asset) / 2;
            }
        },
        route2Brand() {
            this.$router.push({
                name: 'Brand Value',

            });
        },
        handleStartForFree(){
            this.$router.push({name:'Free Trial'})
        },
        updateAmount(newAmount, oldAmount) {
            if (newAmount !== oldAmount && newAmount) {
                this.amount_mindcoin = newAmount;
            }
        },
        updatePrompt(newPrompt, oldPrompt) {
            if (newPrompt !== oldPrompt && newPrompt == 'true') {
                this.prompt = true;
            }
        },
        updateBack(newBack, oldBack) {
            if (newBack !== oldBack && newBack == 'true') {
                this.showBackButton = true;
            }

        },
        toggleTable() {
            this.showFirstTable = !this.showFirstTable;
        },
        closeDialog() {
            this.prompt = false;
            this.private_word_asset = 3;
            this.private_image_asset = 3;
            this.private_media_asset = 3;
            this.public_word_asset = 1;
            this.public_image_asset = 1;
            this.public_media_asset = 1;
            this.calculateAmountMindcoin();
        },
        closeDialog_diy() {
            this.prompt_diy = false;
        },
        handlePayAsYouGo: function () {
            this.prompt = true;
            // here we create the license for the pay as you go option
            let license = {
                "py/object": "shared.flask_models.models.License.License",
                "id": 21, //free demo licese id 21
                "name": "Mindspeller 1.9 Playground",
                "credits": 0,
                "credits_on_assignment": 0,
                "is_legacy": 0,
                "co_limit_characters": 100,
                "monthly_fee": 0,
                "is_demo": 0,
                "is_purchasable": 1,
                "pay_as_you_go": 1,
                "amount": 0,
                "mindcoins": 0,
            }

            // here we adjust the amount based on the input
            let myInt = parseInt(this.amount_mindcoin);
            if (myInt.toString() === this.amount_mindcoin.toString() && this.private_word_asset >= 0 && this.private_image_asset >= 0 && this.private_media_asset >= 0 && myInt > 0 && this.currentlang=='en') {
                license['amount'] = this.amount_mindcoin / 1;
                license['mindcoins'] = this.amount_mindcoin;

                this.$router.push({
                    name: 'Payment Method',
                    params: {
                        selectedLicense: JSON.stringify(license),
                    },
                });
            } else if (myInt.toString() === this.amount_mindcoin.toString() && this.private_word_asset >= 0 && this.private_image_asset >= 0 && this.private_media_asset >= 0 && myInt > 0 && this.currentlang=='nl') {
                license['amount'] = this.amount_mindcoin * 1.5;
                license['mindcoins'] = this.amount_mindcoin;

                this.$router.push({
                    name: 'Payment Method',
                    params: {
                        selectedLicense: JSON.stringify(license),
                    },
                });
            } else {
                this.closeDialog()
                alert_error('Invalid number in the field');
            }
        },
        handleContactSales: function () {
            const mailtoLink = "mailto:contact@mindspeller.com";
            const emailLink = document.createElement("a");
            emailLink.href = mailtoLink;
            emailLink.style.display = "none";
            this.$el.appendChild(emailLink);
            emailLink.click();
            this.$el.removeChild(emailLink);
        },

        onPaymentMethodChange: function (payment_method_id) {
            this.selectedPaymentMethod = payment_method_id;
        },
        changePlan: async function (license) {
            this.selectedLicense = license;

            // here we need to do some modification on the license to align with the pay as you go
            this.selectedLicense.pay_as_you_go = 0;
            if (this.selectedLicense.id == 23) {
                this.selectedLicense.amount = 299.97;
                this.selectedLicense.mindcoins = 750;
            }
            if (this.selectedLicense.id == 24) {
                this.selectedLicense.amount = 1499.97;
                this.selectedLicense.mindcoins = 3750;
            }
            if (this.selectedLicense.id == 25) {
                this.selectedLicense.amount = 2999.97;
                this.selectedLicense.mindcoins = 7500;
            }
            if (this.selectedLicense.id == 27) {
                this.selectedLicense.amount = 49;
                this.selectedLicense.mindcoins = 150;
            }


            this.$router.push({
                name: 'Payment Method',
                params: {
                    selectedLicense: JSON.stringify(this.selectedLicense)
                },
            });
        },
        sortedAsc: function (items) {
            return items.sort((a, b) => {
                if (a.monthly_fee < b.monthly_fee) return -1;
                if (a.monthly_fee > b.monthly_fee) return 1;
                return 0;
            });
        },
    },
    watch: {
        isChecked: {
            handler: 'updateMindcoins',
            immediate: true,
        },
        charge_amount: {
            handler: 'updateAmount',
            immediate: true,
        },
        showPrompt: {
            handler: 'updatePrompt',
            immediate: true,
        },
        ifBack: {
            handler: 'updateBack',
            immediate: true,
        },
        private_word_asset(newValue, oldValue) {
            this.calculateAmountMindcoin();
        },
        private_image_asset(newValue, oldValue) {
            this.calculateAmountMindcoin();
        },
        private_media_asset(newValue, oldValue) {
            this.calculateAmountMindcoin();
        },
        public_word_asset(newValue, oldValue) {
            this.calculateAmountMindcoin();
        },
        public_image_asset(newValue, oldValue) {
            this.calculateAmountMindcoin();
        },
        public_media_asset(newValue, oldValue) {
            this.calculateAmountMindcoin();
        },

    },
};
</script>

<style scoped>
.fade-in-enter-active,
.fade-in-leave-active {
    transition: opacity 0.5s;
}

.fade-in-enter,
.fade-in-leave-to

/* .fade-in-leave-active in <2.1.8 */
    {
    opacity: 0;
}

.info-message {
    color: #2d2e2e;
    text-align: center;
    font-weight: bold;
    border: 3px solid #E3E9FD;
    border-radius: 30px;
    padding: 10px 0;
    /* margin: 20px 0;  */
    font-weight: bolder;
}

.payment-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.payment-table:hover {
    background-color: #f0f0f0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.payment-table th,
.payment-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
}

.payment-table th {
    background-color: #f2f2f2;
}

.payment-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

td,
th {
    border: 1px solid grey;
}

.container_module {
    display: flex;
    margin: 0 auto;
    width: 1300px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.licenses_container {
    display: flex;
    margin: 0 auto;
    width: 1000px;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 10px;
}

.licence_container{
    flex-direction: column;
    gap: 15px;
    background-color: #0A00FF;
    border-radius: 10px;
    color: white;
    width: 30%;
    height: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: #616161;
}
.licence_item_container{
    gap: 15px;
    color: white;
    text-align: left;
    margin-left: 15px;
    margin-top: 10px;
}

.licence_label_container{
    gap: 15px;
    color: white;
    font-weight: 900;
    font-size: large;
    text-align: left;
    margin-left: 10px;
    margin-top: 10px;
}
.licence_price_container{
    gap: 15px;
    color: white;
    font-weight: 900;
    font-size: xx-large;
    text-align: left;
    margin-left: 10px;
}

.licence_detail_container{
    gap: 15px;
    color: white;
    font-weight: 900;
    font-size: small;
    text-align: left;
    margin-left: 10px;
}

.more_info_container{
    display: flex; 
    justify-content: flex-end; 
    margin-right: 10px; 
    margin-top: 20px;
}

.more_info_button {
    display: flex;
    background: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    transition: all 1s;
    padding: 5px;
    width: 25px;
    height: 25px;
    justify-content: center;
}

.subscribe_button {
    background: #ffffff;
    border-radius: 20%;
    font-size: 16;
    color: #000000 !important;
    cursor: pointer;
    text-transform: capitalize;
    transition: all 1s;
    width: 75%;
    height: 30px;
}

.subscribe_container{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;

}
.subscribe_container_mindcoin_model{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    vertical-align: left;   
}

.integer {
    font-size: 20px;
}

.decimal {
    font-size: 12px;
    top: 0;
    left: 20px;
}

.horizontal {
    width: 89%;
    margin: 1vh auto;
    border-top: 1px solid #616161;
}

.custom-icon {
    margin-right: 1vw;
    font-size: 18px;
    color: white;
    background-color: black;
    border-radius: 50%;
}

.button_pay {
    background: #0A00FF;
    border-radius: 2vw;
    font-size: 24;
    color: #ffffff !important;
    cursor: pointer;
    text-transform: capitalize;
    transition: all 1s;
}

.button_pay a {
    color: #ffffff !important;
}

.button_pay:hover {
    background-color: #92a0b3;
}

.q-field__native {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
}

.q-field__label {
    color: #333;
    font-weight: 500;
}

.watermark {
    font-size: 12px;
    font-weight: bolder;
    text-align: center;
    position: absolute;
    top: 40%;
    left: 90%;
    transform: translate(-50%, -50%) rotate(45deg);
    color: #ffffff;
    pointer-events: none;
    background-color: #333;
    width: 150px;
    padding: 2px;
}

.my-card:hover {
    background-color: #dcdcdc;
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-description {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2d2e2e;
    font-weight: lighter;
    padding: 15px;
    /* Increased padding for better spacing */
    border-radius: 8px;
    /* Slightly increased border radius */
    transition: background-color 0.3s, box-shadow 0.3s;
    /* Added box-shadow transition */
}

.feature-description:hover {
    background-color: #f0f0f0;
    font-weight: bolder;
}

.icon {
    margin-right: 5px;
}

.text {
    margin-left: 5px;
}
.inner-container {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
.sub-container {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: rgb(234, 228, 228);
    }

.flag-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ffffff;
}
.custom-table {
    border-collapse: collapse;
    width: 100%;
}

.custom-table td {
    text-align: center;
    padding: 2px;
    border: 2px solid white;
}

.button {
    display: flex;
    padding: 10px;
    border-radius: 50px;
    background-color: white;
    color: black;
    text-decoration: none;
    font-weight: bold;
    justify-content: space-between;
    width: 200px;
    align-items: center;
    cursor: pointer;
}

.arrow {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: black;
    color: white;
    text-align: center;
    line-height: 20px;
    margin-left: 10px;
    font-size: 24px;
}

.subsc-pg2 {
    padding-top: 8vw;
    display: block;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

</style>

